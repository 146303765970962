<template>
  <BaseDialog
    :propDialog="dialog"
    :validity="formValid"
    :title="title"
    :topIcon="order.status === 'cancelled' ? '' : topIcon"
    :botIcon="
      toUpdate || type === 'invoice' || order.status === 'cancelled'
        ? ''
        : 'moreRequests'
    "
    :loader="loading"
    :actionButton="actionName"
    :actionable="actionConditions"
    @selectTopIcon="switchView"
    @selectBotIcon="openAddRequest"
    @confirm="onConfirm"
    @closeDialog="setDialog"
  >
    <div v-if="!toUpdate" class="sticky-el--title text-h6 primary--text">
      {{ cloneOrder.tableName }} - {{ cloneOrder.customerName }}
    </div>
    <div v-if="type === 'order'" class="mb-4">
      <BaseEntry label="order.order-num" :content="order.orderNum" />
      <BaseEntry
        label="order.check-in"
        :content="$helper.formattedDate(order.createdAt)"
      />
      <BaseEntry label="order.creator" :content="order.submittedBy" />
      <BaseEntry
        label="order.current-amount"
        contentColor="primary--text"
        :content="orderValue.toLocaleString()"
      />
    </div>
    <div v-else>
      <BaseEntry label="order.order-num" :content="order.orderNum" />
      <BaseEntry
        label="invoice.check-in"
        :content="$helper.formattedDate(order.checkinAt)"
      />
      <BaseEntry
        label="invoice.check-out"
        :content="$helper.formattedDate(order.createdAt)"
      />
      <BaseEntry
        class="mb-4"
        label="invoice.creator"
        :content="order.updatedBy"
      />
    </div>
    <OrderDetails
      v-show="toUpdate"
      isOrder
      :tableCode.sync="cloneOrder.tableCode"
      :customerName.sync="cloneOrder.customerName"
      :orderId="cloneOrder._id"
      :isValid.sync="formValid"
    />
    <KitchenRequestList
      v-show="!toUpdate"
      :orderId="type === 'order' ? order._id : order.orderId"
      container="order-dialog"
      :fullScreen="false"
      @loadRequests="setRequests"
    />
  </BaseDialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import OrderDetails from './OrderDetails'
import KitchenRequestList from '../kitchen-requests/KitchenRequestList'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import OrderService from '@/services/modules/OrderService'
import { displaySuccessMessage, displayErrorMessage, hasRole } from '@/utils'

export default {
  name: 'ViewOrder',

  components: {
    OrderDetails,
    KitchenRequestList
  },

  props: {
    order: Object,
    dialog: Boolean,
    toUpdate: Boolean,
    type: {
      validator: function(value) {
        return ['order', 'invoice'].includes(value)
      }
    }
  },

  data: () => ({
    title: String,
    loading: false,
    cloneOrder: null,
    formValid: true,
    requests: null,
    actionName: ''
  }),

  computed: {
    ...mapState('common', ['tables']),

    actionConditions() {
      if (this.order.status === 'cancelled') {
        return false
      } else if (this.type === 'invoice') {
        return false
      } else if (this.toUpdate) {
        return true
      } else if (hasRole('Cashier')) {
        return true
      } else {
        return false
      }
    },

    topIcon() {
      if (this.type === 'invoice') {
        return ''
      } else {
        if (this.toUpdate) {
          return 'food'
        } else {
          return 'edit'
        }
      }
    },

    orderValue() {
      if (Array.isArray(this.requests)) {
        const reducer = (acc, item) => {
          if (item.status !== 'cancelled') {
            return acc + item.price * item.quantity
          } else {
            return acc
          }
        }
        const totalValue = this.requests.reduce(reducer, 0)
        return totalValue
      } else {
        return 0
      }
    }
  },

  mounted() {
    this.$mitt.on('createInvoice', this.onCreateInvoice)
    this.$mitt.on('setDialog', this.setDialog)
  },
  beforeDestroy() {
    this.$mitt.off('createInvoice', this.onCreateInvoice)
    this.$mitt.off('setDialog', this.setDialog)
  },

  watch: {
    toUpdate: {
      immediate: true,
      handler: function(val) {
        if (val === false) {
          this.title = 'order.view-order'
          this.actionName = 'order.init-receipt'
          this.formValid = true
        } else {
          this.title = 'order.update-order'
          this.actionName = 'common.confirm'
        }
        this.cloneOrder = cloneDeep(this.order)
      }
    }
  },

  methods: {
    ...mapActions('common', ['updateTable']),

    setDialog(val) {
      this.$emit('update:dialog', val)
    },

    setRequests(reqs) {
      this.requests = reqs
    },

    switchView() {
      if (this.toUpdate === true && !isEqual(this.order, this.cloneOrder)) {
        this.updateOrder(true)
      }
      this.$emit('update:toUpdate', !this.toUpdate)
    },

    openAddRequest() {
      this.$emit('openAddRequest', { item: this.order, type: this.type })
    },

    onConfirm() {
      if (this.toUpdate) {
        this.updateOrder()
      } else {
        this.openConfirmDialog()
      }
    },

    openConfirmDialog() {
      const item = {
        type: 'createInvoice',
        data: this.type === 'order' ? this.order._id : this.order.orderId,
        confirmTitle: 'order.close-order-title',
        confirmContent: this.$t('order.close-order-text', {
          order: `${this.order.tableName} - ${this.order.customerName}`
        })
      }
      this.$mitt.emit('openConfirmDialog', item)
    },

    onCreateInvoice() {
      this.setDialog(false)
    },

    updateOrder(keepDialog) {
      this.loading = true
      OrderService.updateOrder(this.cloneOrder._id, this.cloneOrder)
        .then(res => {
          displaySuccessMessage('order.message-edit-order')
          const {
            tableCode: oldTableCode,
            customerName: oldCustomerName
          } = this.order
          const {
            _id,
            tableCode: newTableCode,
            customerName: newCustomerName,
            createdAt
          } = res.data
          const orderInfo = {
            orderId: _id,
            customerName: newCustomerName,
            createdAt
          }
          if (newTableCode !== oldTableCode) {
            this.updateTable({ tableCode: oldTableCode, orderInfo: null })
            if (newTableCode !== 'takeaway') {
              this.updateTable({ tableCode: newTableCode, orderInfo })
            }
            this.$socket.client.emit('update-table', this.tables)
          } else if (newCustomerName !== oldCustomerName) {
            this.updateTable({ tableCode: newTableCode, orderInfo })
            this.$socket.client.emit('update-table', this.tables)
          }
          this.$mitt.emit('updateOrder', res.data)
          this.cloneOrder = res.data
          this.setDialog(!!keepDialog)
          this.$socket.client.emit('update-request')
        })
        .catch(err => displayErrorMessage(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style scoped></style>
